#broker-choice-modal.md-default{font-size: 14px; background-color: rgba(0,0,0,0.7); z-index:100000;}

#broker-choice-modal.md-default.hide{display: none;}

#broker-choice-modal .md-container{max-width: 600px; border-radius: 8px;}

#broker-choice-modal  .md-title{font-size: 14px; padding: 15px 20px; font-weight: bold; border-color: #afafaf;}

#broker-choice-modal .md-content{color:#696969 ; padding: 15px 20px; line-height: 1.3; font-size: 14px;}

#broker-choice-modal .md-content span{display: block; margin-top: 3px;}

#broker-choice-modal .md-cmd-btn{
    border: 0;
    box-shadow: none;
    color: #fff;
    border-radius: 8px;
    margin: 0;
    background-color: #1da1f0;
    width: calc(50% - 10px);
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    text-decoration: none;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    
}

#broker-choice-modal .md-cmd-btn:hover{
    cursor:pointer;
}

#broker-choice-modal .md-close-btn{display: none;}

#broker-choice-modal .md-btn-wrap{padding-bottom: 20px; text-align: center; color: #696969;}

#broker-choice-modal .md-btn-wrap::after{content: attr(data-content); display: block; order:2;}

#broker-choice-modal .md-btn1{order:1}

#broker-choice-modal .md-btn2{order:3}

#broker-choice-modal .md-content p{margin-bottom: 0; font-size: 14px;}



@media (min-width: 520px) {
    #broker-choice-modal .md-cmd-btn{
        width: calc(50% - 18px);
    }

    .es #broker-choice-modal .md-cmd-btn{ width: calc(50% - 16px);}

    .ru #broker-choice-modal .md-cmd-btn , .kz #broker-choice-modal .md-cmd-btn{

        line-height: 15px;
        padding: 5px 10px;
    }


    

    
}


@media (max-width: 520px) {

    #broker-choice-modal .md-container{max-width: 356px;}

    #broker-choice-modal  #md-string .md-title{border:0; }
    #broker-choice-modal  .md-cmd-btn{
        width:100%;
   
    text-align: center;
    margin: auto;
    }
    #broker-choice-modal .md-btn-wrap{ flex-wrap:wrap;}

    #broker-choice-modal .md-btn-wrap::after{ width: 100%; padding: 5px;}



    .ru #broker-choice-modal .md-cmd-btn , .kz #broker-choice-modal .md-cmd-btn{font-size: 13px; max-width: 260px;}

    #broker-choice-modal .md-title,
    #broker-choice-modal .md-content{padding: 15px 10px}

    #broker-choice-modal .md-btn-wrap{padding: 0 10px 15px;}
}

@media (max-width: 620px) and (min-width: 520px) {
    .es #broker-choice-modal .md-cmd-btn{font-size: 13px;}

}